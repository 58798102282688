/* eslint no-console:0 */

import $ from 'jquery-custom';
import _ from 'underscore';

import 'bootstrap/js/dist/alert';
import 'devise_authy';

import 'password_strength';

// clear localStorage when loading devise pages (specifically sign in) to clear
// footable saved state
localStorage.clear();
