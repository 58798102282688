import $ from 'jquery-custom';
import _ from 'underscore';
require('pwstrength-bootstrap/dist/pwstrength-bootstrap');

$(function () {
  $('.password-strength-meter[type=password]').pwstrength({
    common: {
      debug: false,
      minChar: 8
    },
    ui: {
      showStatus: true,
      showErrors: false,
      showVerdictsInsideProgressBar: true,
      verdicts: ['Invalid', 'Invalid', 'Invalid', 'Invalid', 'Invalid', 'Valid'],
      scores: [0, 10, 20, 30, 41]
    },
    rules: {
      activated: {
        wordNotEmail: false,
        wordMinLength: false,
        wordMaxLength: false,
        wordInvalidChar: false,
        wordSimilarToUsername: false,
        wordSequences: false,
        wordTwoCharacterClasses: false,
        wordRepetitions: false,
        wordLowercase: false,
        wordUppercase: false,
        wordOneNumber: false,
        wordThreeNumbers: false,
        wordOneSpecialChar: false,
        wordTwoSpecialChar: false,
        wordUpperLowerCombo: false,
        wordLetterNumberCombo: false,
        wordLetterNumberCharCombo: false,
        wordIsACommonPassword: false
      }
    },
    i18n: {
      t: function (key) {
        let result;

        switch(key) {
          case "veryWeak":
          case "weak":
          case "normal":
          case "medium":
          case "strong":
            result = "Invalid";
            break;
          case "veryStrong":
            result = "Valid";
            break;
          default:
            result = key;
            break;
        }

        return result;
      }
    }
  });

  $(".password-strength-meter").pwstrength("addRule", "LowercaseMatch", function (options, word, score) {
    return word.match(/[a-z]/) && score;
  }, 10, true);

  $(".password-strength-meter").pwstrength("addRule", "UppercaseMatch", function (options, word, score) {
    return word.match(/[A-Z]/) && score;
  }, 10, true);

  $(".password-strength-meter").pwstrength("addRule", "NumberMatch", function (options, word, score) {
    return word.match(/[0-9]/) && score;
  }, 10, true);

  $(".password-strength-meter").pwstrength("addRule", "PuncuationMatch", function (options, word, score) {
    return word.match(/[^a-zA-Z0-9]/) && score;
  }, 10, true);

  $(".password-strength-meter").pwstrength("addRule", "MinLength", function (options, word, score) {
    return word.length >= 8 && score;
  }, 11, true);
});
